import React, { ChangeEvent, FormEvent, useState } from 'react'
import { Box, Paper, TextField, Typography, Button, FormControl, InputLabel, Input, InputAdornment, IconButton, FormHelperText, FormControlLabel, Checkbox, Select, MenuItem } from '@mui/material'
import { Link, useNavigate } from "react-router-dom"
import { isEmail } from '../../functions/EmailVerificator'
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { PasswordError, UserRegister } from '../../store/models/User';
import { VisibilityOff, Visibility } from '@mui/icons-material'
import { isPassword } from '../../functions/PasswordVerificator';
import { isNameOrLastname } from '../../functions/NameVerificator';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import axios from 'axios';
import styles from '@mui/material/Button/buttonClasses';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { UsernameValidator } from './UsernameValidator';
import { genders } from 'components/enums/Genders';

export const RegisterForm = () => {
    const [user, setUser] = useState<UserRegister>({
        name: "",
        lastname: "",
        birthDate: dayjs(),
        email: "",
        username: "",
        password: "",
        confirmPassword: "",
        gender: "",
        phoneNumber: "",
        isSuscribedNewsletter: false
    })
    const [emailError, setEmailError] = useState({ state: false, message: "" })
    const [phoneError, setPhoneError] = useState({ state: false, message: "" })
    const [passwordError, setPasswordError] = useState<PasswordError>({ state: false, message: "" })
    const [confError, setConfError] = useState({ state: false, message: "" })
    const [nameError, setNameError] = useState({ state: false, message: "" })
    const [lastnameError, setLastnameError] = useState({ state: false, message: "" })
    const [usernameError, setUsernameError] = useState({ state: false, message: "" })
    const [birthdateError, setBirthdateError] = useState({ state: false, message: "" })
    const [requestState, setRequestState] = useState({ state: "initial", message: "" })
    const [showPassword, setShowPassword] = React.useState(false);

    const navigate = useNavigate()

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };


    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setUser({ ...user, [name]: value })
        setEmailError({ state: false, message: "" })
        setPasswordError({ state: false, message: "" })
        setConfError({ state: false, message: "" })
        setNameError({ state: false, message: "" })
        setLastnameError({ state: false, message: "" })
        setBirthdateError({ state: false, message: "" })
        setUsernameError({ state: false, message: "" })
    }

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isEmail(user.email)) {
            setEmailError({
                state: true, message: "Por favor ingrese un correo valido."
            })
        }
        if (user.phoneNumber.length < 9) {
            setPhoneError({
                state: true, message: "Por favor ingrese un numero telefonico valido."
            })
        }
        if (!isPassword(user.password)) {
            // setPasswordError({
            //     state: true, message: "La contraseña debe contener al menos 8 caracteres, contener mayúsculas y minúsculas, contener al menos un número y un carácter especial"
            // })
            setPasswordError({
                state: true, message:
                    <div>
                        La contraseña debe contener:
                        <ul>
                            <li>Al menos 8 caracteres</li>
                            <li>Una mayúscula y minúsculas</li>
                            <li>Al menos un número y un carácter especial</li>
                        </ul>
                    </div>
            })
        }
        if (user.password != user.confirmPassword) {
            setConfError({
                state: true, message: "Las contraseñas no coinciden, por favor revise que sean identicas."
            })
        }
        if (UsernameValidator({ username: user.name }) === true) {
            setNameError({
                state: true, message: "Por favor ingrese un nombre valido y sin insultos" 
            })
        }
        if (!isNameOrLastname(user.name)) {
            setNameError({
                state: true, message: "Por favor ingrese un nombre valido"
            })
        }
        if (UsernameValidator({ username: user.lastname }) === true) {
            setLastnameError({
                state: true, message: "Por favor ingrese un apellido valido y sin insultos"
            })
        }
        if (!isNameOrLastname(user.lastname)) {
            setLastnameError({
                state: true, message: "Por favor ingrese un apellido valido"
            })
        }
        if (UsernameValidator({ username: user.username }) === true) {
            setUsernameError({
                state: true, message: "Por favor ingrese un username valido y sin insultos"
            })
        }
        if (!emailError.state && !phoneError.state && !passwordError.state && !confError.state && !nameError.state && !lastnameError.state && !usernameError.state && !birthdateError.state) {
            //Action register
            setRequestState({ ...requestState, state: "loading" })

            axios.post(`${process.env.REACT_APP_TAUTH_URL}/api/account/register`, {
                ...user,
                gender: parseInt(user.gender)
            })
                .then((res) => {
                    setRequestState({ ...requestState, state: "success" })
                })
                .catch((error) => {
                    // const errorMessage = error.response.data.errors[0].split("\n")[0].split(": ")[1]
                    setRequestState({ message: "Ops! Algo salio mal, intentelo de nuevo o revise la informacion ingresada.", state: "error" })
                })
        }
    };


    if (requestState.state === "success") {
        return (
            <Box>
                <Paper elevation={6} sx={{ p: 2, width: { sm: 440, xs: "95vw" }, display: 'flex', alignItems: 'center', flexDirection: "column", gap: 2 }}>
                    <Typography variant='h3' fontWeight={"bold"} textAlign={"center"}>
                        ¡Registro exitoso!
                    </Typography>
                    <CheckCircleOutlineIcon sx={{ color: "#ffa400", fontSize: 100 }} />
                    <Box>
                        <Typography sx={{ fontSize: '0.9rem' }}>
                            Confirme su cuenta para poder ingresar
                        </Typography>
                    </Box>
                    <Button
                        variant="contained"
                        type='submit'
                        onClick={() => { navigate("/login") }}
                    >
                        Iniciar sesión
                    </Button>
                </Paper>
            </Box>
        )
    } else {
        return (
            <Box>
                <Paper elevation={6} sx={{ p: 2, width: { sm: 440, xs: "95vw" } }}>
                    <Typography variant='h5' fontWeight={"400"}>
                        Registro
                    </Typography>
                    <Box component="form" noValidate={false} sx={{ px: 4, display: 'flex', flexDirection: 'column', gap: 2 }} onSubmit={handleSubmit}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="name"
                            label="Nombre"
                            name="name"
                            autoComplete="name"
                            autoFocus
                            variant="standard"
                            onChange={handleInputChange}
                            helperText={nameError.message}
                            error={nameError.state}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="lastname"
                            label="Apellido"
                            name="lastname"
                            autoComplete="lastname"
                            autoFocus
                            variant="standard"
                            onChange={handleInputChange}
                            helperText={lastnameError.message}
                            error={lastnameError.state}
                        />
                        <FormControl variant="standard" required fullWidth>
                            <InputLabel id="demo-simple-select-label">Genero</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={user.gender}
                                label="Genero"
                                onChange={(event) => {
                                    setUser({ ...user, gender: event.target.value })
                                }}
                            >
                                <MenuItem value={genders.Femenino}>Femenino</MenuItem>
                                <MenuItem value={genders.Masculino}>Masculino</MenuItem>
                                <MenuItem value={genders.Otro}>Otro</MenuItem>
                            </Select>
                        </FormControl>
                        <DatePicker
                            label="Fecha de nacimiento"
                            value={user.birthDate}
                            onChange={
                                (value) => {
                                    setUser({ ...user, birthDate: value })
                                    setBirthdateError({ state: false, message: "" })
                                }
                            }
                            disableFuture
                            slotProps={{
                                textField: {
                                    helperText: birthdateError.message,
                                    color: birthdateError && 'error',
                                    focused: birthdateError.state

                                }
                            }}
                            // onError={() => { setBirthdateError({ state: true, message: "Fecha no permitida" }) }}
                            sx={{ mt: 2 }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Correo electronico"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            variant="standard"
                            onChange={handleInputChange}
                            helperText={emailError.message}
                            error={emailError.state}
                        />
                        <Box
                            sx={{
                                "input": {
                                    ...styles,
                                    height: 50,
                                    fontSize: 15
                                },
                                width: "100%",
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                ".PhoneInput": {
                                    width: '100%'
                                }

                            }}
                        >
                            <PhoneInput
                                international
                                countryCallingCodeEditable={false}
                                defaultCountry="CL"
                                value={user.phoneNumber}
                                onChange={(value) => {
                                    setUser({ ...user, phoneNumber: value as string })
                                    setPhoneError({ state: false, message: "" })
                                }}
                                limitMaxLength={true}
                            />
                            {
                                phoneError.state &&
                                <FormHelperText sx={{ color: "#c03e3e !important" }}>
                                    {phoneError.message}
                                </FormHelperText>
                            }

                        </Box>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Nombre de usuario"
                            name="username"
                            // autoComplete="username"
                            autoFocus
                            variant="standard"
                            onChange={handleInputChange}
                            helperText={usernameError.message}
                            error={usernameError.state}
                        />

                        <FormControl sx={{ width: "100%", my: 1 }} variant="standard">
                            <InputLabel required
                                color={passwordError.state ? "error" : "primary"}
                                focused={passwordError.state}>
                                Contraseña
                            </InputLabel>
                            <Input
                                required
                                onChange={handleInputChange}
                                name='password'
                                error={passwordError.state}
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText
                                className={passwordError.state ? 'Mui-error' : ""}
                            >
                                {passwordError.message}
                            </FormHelperText>
                        </FormControl>

                        <FormControl sx={{ width: "100%", my: 1 }} variant="standard">
                            <InputLabel required
                                color={passwordError.state ? "error" : "primary"}
                                focused={passwordError.state}
                            >
                                Confirmar contraseña
                            </InputLabel>
                            <Input
                                required
                                onChange={handleInputChange}
                                name='confirmPassword'
                                error={confError.state}
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText className={passwordError.state ? 'Mui-error' : ""} >{confError.message}</FormHelperText>
                        </FormControl>
                        
                        <FormControlLabel
                            name='eula'
                            sx={{ fontSize: '0.8rem' }}
                            control={<Checkbox required />}
                            label={
                                <p>Acepta los <Link to="/termsconditions" target="_blank">
                                    termino y condiciones
                                </Link>
                                </p>
                            }

                        />
                        <FormControlLabel
                            name='isSuscribedNewsletter'
                            control={<Checkbox onChange={(event) => {
                                setUser({ ...user, isSuscribedNewsletter: event.target.checked })
                            }} />}
                            sx={{ fontSize: '0.8rem' }}
                            label={
                                <p>Desea suscribirse a las noticias y ofertas de Tesseract</p>
                            }
                        />
                        <Box my={2}>
                            <Typography sx={{ fontSize: '0.9rem' }}>
                                ¿Ya tienes una cuenta? <Link to={'/login'}>Inicia sesión ahora!</Link>
                            </Typography>

                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Typography sx={{ fontSize: '0.9rem' }} color={"error"}>
                                {requestState.state === "error" && requestState.message}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Button
                                variant="contained"
                                type='submit'
                                disabled={requestState.state === "loading"}
                            >
                                Confirmar registro
                            </Button>
                        </Box>

                    </Box>
                </Paper>
            </Box>
        )
    }
}
