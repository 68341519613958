import React from 'react'
import { bannedWord } from './badwordsList';

interface Props {
    username: string
}

function eliminarLetrasRepetidasConsecutivas(texto: string) {
    return texto.replace(/(.)\1+/g, '$1');
}

function normalizarPalabra(palabra: string) {
    return palabra.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export const UsernameValidator = (props: Props) => {
    let { username } = props;

    username = username.toLowerCase()

    username = username.replace(/4/g, 'a');
    // Reemplazar 1 por i
    username = username.replace(/1/g, 'i');
    // Reemplazar 3 por e
    username = username.replace(/3/g, 'e');
    // Reemplazar @ por a
    username = username.replace(/@/g, 'a');
    // Reemplazar k por c
    username = username.replace(/k/g, 'c');
    // Reemplazar z por s
    username = username.replace(/z/g, 's');
    // Reemplazar 7 por t
    username = username.replace(/7/g, 't');

    username = eliminarLetrasRepetidasConsecutivas(username)

    username = normalizarPalabra(username)

    let validator = false

    bannedWord.forEach((word: any) => {
        if (username.includes(word) === true) {
            validator = true
        }
    });

    return validator

}
