export const bannedWord: any = [
    "tonto",
    "maraco",
    "aweonao",
    "ahueonao",
    "rechuchetumare",
    "puta",
    "putita",
    "puton",
    "putaso",
    "conchetumare",
    "reconchetumare",
    "maricon",
    "mierda",
    "puto",
    "puta",
    "nigga",
    "nigger",
    "niger",
    "niga",
    "lacra",
    "chuchetumare",
    "nud",
    "nude",
    "sex",
    "sexo",
    "maraca",
    "culiao",
    "wea",
    "macaco",
    "nazi",
    "kkk",
    "hitler",
    "jitler",
    "musolini",
    "stalin",
    "pinochet",
    "pinoshet",
    "allende",
    "ayende",
    "pene",
    "pico",
    "escroto",
    "vagina",
    "bagina",
    "orgasmo",
    "poto",
    "caca",
    "ctm",
    "tula"
]
