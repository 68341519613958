
import { createTheme } from "@mui/material/styles";

export const appTheme = createTheme({
    palette: {
        primary: {
            main: '#ffa400',
            contrastText: "#231f20",
            light: "#f2f2f2",
            // dark: "#fff"
        },
        secondary: {
            main: '#231f20',
            contrastText: "#f2f2f2",
            light: "#f2f2f2",
            dark: "#231f20"
        },
        info: {
            main: '#ffa400',
            contrastText: "#231f20",
            light: "#f2f2f2",
            dark: "#f2f2f2"
        }
    },
});