import React, { ChangeEvent, FormEvent, useState } from 'react'
import { Box, Paper, TextField, Typography, Button, FormControl, InputLabel, Input, InputAdornment, IconButton, FormHelperText, FormControlLabel, Checkbox } from '@mui/material'
import { Link } from "react-router-dom"
import { isEmail } from '../../functions/EmailVerificator'
import { VisibilityOff, Visibility } from '@mui/icons-material'
import axios from 'axios'
import { useAppSelector } from '../../hooks/hooks'
import { RootState } from '../../store'
import { LoadingCube } from 'components/commons/LoadingCube'
import { log } from 'console'


export const LoginForm = () => {
    const [user, setUser] = useState({
        email: "",
        password: ""
    })
    const [rememberUser, setRememberUser] = useState(false)
    const [emailError, setEmailError] = useState({ state: false, message: "" })
    const [passwordError, setPasswordError] = useState({ state: false, message: "" })
    const [loginError, setLoginError] = useState({ state: false, message: "" })
    const [showPassword, setShowPassword] = React.useState(false);
    const [reqStatus, setReqStatus] = useState({ state: "", message: "" })

    const { url, status } = useAppSelector((state: RootState) => state.redirect);


    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setUser({ ...user, [name]: value })
        setEmailError({ state: false, message: "" })
        setPasswordError({ state: false, message: "" })

    }

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isEmail(user.email)) {
            setEmailError({
                state: true, message: "Por favor ingrese un correo valido."
            })
        }
        if (!emailError.state && !passwordError.state) {
            //Action Login
            setReqStatus({ state: "loading", message: "" })
            axios.post(`${process.env.REACT_APP_CONTENTMANAGER_URL}/api/account/login`, user)
                .then((res) => {

                    const token = res.data.result.jwTtoken
                    // if (rememberUser) {
                    //     window.localStorage.setItem("jwt", token);
                    // }
                    // axios.get(
                    //     `${process.env.REACT_APP_CONTENTMANAGER_URL}/api/auth/save-token`,
                    //     {
                    //         headers: {
                    //             Authorization: `Bearer ${token}`,
                    //         }
                    //     }
                    // )
                    //     .then((result) => {
                    window.location.href = `${url}/redirect-login?jwtToken=${token}`
                    //     }).catch((err) => {
                    //         console.log("ERROR",err)
                    //     });
                })
                .catch((error) => {
               
                    let errorMessage = error.response.data.message
                    setReqStatus({ state: "error", message: "" })

                    setLoginError({ state: true, message: errorMessage })
                })
        }
        // dispatch(startLoginWithEmailPassword(loginUser));
    };
    const handleReConfirmAcc = async () => {
        if (!isEmail(user.email)) {
            setEmailError({
                state: true, message: "Por favor ingrese un correo valido."
            })
        }
        setReqStatus({ state: "loading", message: "Cargando..." })
        if (!emailError.state) {
            //Action Login
            axios.post(`${process.env.REACT_APP_TAUTH_URL}/api/account/confirmationemail?email=${user.email}`)
                .then((res) => {
                    setReqStatus({ state: "success", message: "" })
                })
                .catch((error) => {
                    // console.log(error.response.data.message);
                    // let errorMessage = error.response.data.message
                    setReqStatus({ state: "error", message: "" })
                    setLoginError({ state: true, message: "" })
                })
        }
        // dispatch(startLoginWithEmailPassword(loginUser));
    };
    if (reqStatus.state == "loading") {
        return <LoadingCube />
    }
    return (
        <Box>
            <Paper elevation={6} sx={{ p: 2, width: { sm: 440, xs: "95vw" } }}>
                {/* <img src="https://tesseractwebresources.blob.core.windows.net/webimages/Icons/T_v2.png" alt="" /> */}
                <Typography variant='h5' fontWeight={"400"}>
                    Inicio sesión
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <FormHelperText
                        className={loginError.state ? 'Mui-error' : ""}
                        sx={{ fontSize: '1rem', fontWeight: 'bold', textAlign: 'center' }}
                    >
                        {loginError.message}
                        {
                            loginError.message == "User not confirmed" &&
                            <Typography sx={{ fontSize: '0.9rem', cursor: 'pointer' }} onClick={handleReConfirmAcc}>
                                <a>Reenviar correo de confirmacion </a>
                            </Typography>
                        }
                    </FormHelperText>
                </Box>
                <Box component="form" noValidate={false} sx={{ px: 4 }} onSubmit={handleSubmit}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Correo electronico"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        variant="standard"
                        onChange={handleInputChange}
                        helperText={emailError.message}
                        error={emailError.state}
                    />
                    <FormControl sx={{ width: "100%", my: 1 }} variant="standard">
                        <InputLabel htmlFor="standard-adornment-password" required>Contraseña</InputLabel>
                        <Input
                            required
                            onChange={handleInputChange}
                            name='password'
                            error={passwordError.state}
                            id="standard-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <FormHelperText >{passwordError.message}</FormHelperText>
                    </FormControl>

                    {/* <FormControlLabel
                        name='eula'
                        sx={{ fontSize: '0.8rem' }}
                        control={<Checkbox
                            onChange={(event) => {
                                setRememberUser(event.target.checked)
                            }}
                        />}
                        label={
                            <p style={{ fontSize: "0.9rem" }}>Recordar usuario</p>
                        }

                    /> */}
                    <Box my={2}>
                        <Typography sx={{ fontSize: '0.9rem' }}>
                            ¿No tiene una cuenta? <Link to={'/register'}>Registrate ahora!</Link>
                        </Typography>
                        <Typography sx={{ fontSize: '0.9rem' }}>
                            <Link to={'/forgot-password'}>¿No puede acceder a su cuenta? </Link>
                        </Typography>
                    </Box>


                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Button
                            variant="contained"
                            type='submit'
                            disabled={reqStatus.state === 'loading'}
                        >
                            Iniciar sesión
                        </Button>
                    </Box>

                </Box>
            </Paper>
        </Box>
    )
}
