import React, { CSSProperties } from 'react'
import './style.css'
interface CubeStyles extends CSSProperties {
    '--i'?: string;
}
export const LoadingCube = () => {
    const cubeStyles: CubeStyles = {
        '--i': '0',
    };
    const cubeStyle1: CubeStyles = {
        '--i': '1',
    };
    const cubeStyle2: CubeStyles = {
        '--i': '2',
    };
    const cubeStyle3: CubeStyles = {
        '--i': '3',
    };
    return (
        <div className="cube-loader">
            <div className="cube-top"></div>
            <div className="cube-wrapper">
                <span style={cubeStyles} className="cube-span"></span>
                <span style={cubeStyle1} className="cube-span"></span>
                <span style={cubeStyle2} className="cube-span"></span>
                <span style={cubeStyle3} className="cube-span"></span>
            </div>
        </div>
    )
}
