export const isPassword = (password: string): boolean => {

    //if the password has 0 caracters we ignore 
    if (password.length === 0) {
        return true;
    }

    //mas de 8 caracteres
    if (password.length < 8) {
        return false;
    }

    var lower = /[a-z]/
    var upper = /[A-Z]/
    var number = /[0-9]/
    var special = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/

    if (lower.test(password) && upper.test(password) && number.test(password) && special.test(password)) {
        return true;
    } else {
        return false;
    }
}